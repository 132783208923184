<template>
  <div class="user-app-list-page w-100">
    <basic-subheader>
      <template v-slot:filters>
        <b-row>
          <div class="p-4">
            <basic-input
              label="Tên lộ trình"
              placeholder="Nhập tên lộ trình"
              name="name"
              class="route-input"
              :value.sync="filters.name"
              @keyup.native.enter="loadData()"
            ></basic-input>
          </div>
          <div class="p-4">
            <basic-select
              label="Nhóm lộ trình"
              placeholder="Chọn nhóm lộ trình"
              name="group"
              class="route-input"
              :options="types"
              value-label="text"
              track-by="value"
              changeValueByObject
              :allow-empty="true"
              :value.sync="filters.type"
              @update:value="loadData()"
            ></basic-select>
          </div>
        </b-row>
      </template>
      <template v-slot:actions>
        <b-button
          v-if="isWritePermission"
          class="btn btn-success"
          type="button"
          @click="create"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm mới lộ trình
        </b-button>
      </template>
    </basic-subheader>
    <Wrapper>
      <template-table
        :column="column"
        :data="data"
        :paging="paging"
        :tableAction="false"
        :selectAction="false"
        :searchAction="false"
        @sortBy="sortRequest"
      >
        <template v-slot:body="{ item }">
          <td>
            {{ item.code }}
          </td>
          <td class="cell-overflow">
            <div class="text-overflow">
              {{ item.name }}
            </div>
          </td>
          <td style="width: 20px">
            <action-dropdown
              :value="item"
              :show_copy="false"
              :show_view="false"
              :show_delete="!showBtnActive(item) && isWritePermission"
              :show_edit="false"
              :boundary="data.length > 2 ? 'scrollParent' : 'window'"
              @delete="showPopupChangeStatus(item, 0)"
            >
              <template>
                <b-dropdown-text tag="div" class="navi-item cursor-pointer">
                  <a
                    class="navi-link text-primary"
                    @click="goToDetailScreen(item)"
                  >
                    <span class="menu-icon svg-icon svg-icon-sm">
                      <inline-svg
                        class="svg-icon"
                        src="/media/svg/icons/Neolex/Basic/eye.svg"
                      />
                    </span>
                    <span class="navi-text text-primary"
                      >Xem chi tiết lộ trình</span
                    >
                  </a>
                </b-dropdown-text>
                <b-dropdown-divider
                  v-if="isWritePermission"
                ></b-dropdown-divider>
                <b-dropdown-text
                  v-if="isWritePermission"
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="editRoute(item)"
                >
                  <a class="navi-link text-primary">
                    <span class="menu-icon svg-icon svg-icon-sm">
                      <inline-svg
                        class="svg-icon"
                        src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                      />
                    </span>
                    <span class="navi-text text-primary"
                      >Chỉnh sửa lộ trình</span
                    >
                  </a>
                </b-dropdown-text>
                <b-dropdown-divider
                  v-if="isWritePermission"
                ></b-dropdown-divider>
                <!-- <b-dropdown-text
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="copyRoute(item)"
                >
                  <a class="navi-link text-primary">
                    <span class="menu-icon svg-icon svg-icon-sm">
                      <inline-svg
                        class="svg-icon"
                        src="/media/svg/icons/Neolex/Basic/copy.svg"
                      />
                    </span>
                    <span class="navi-text text-primary"
                      >Sao chép lộ trình</span
                    >
                  </a>
                </b-dropdown-text>
                <b-dropdown-divider></b-dropdown-divider> -->
                <b-dropdown-text
                  v-if="isWritePermission && showBtnActive(item)"
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="showPopupChangeStatus(item, 1)"
                >
                  <a class="navi-link text-primary">
                    <span class="menu-icon svg-icon svg-icon-sm">
                      <inline-svg
                        class="svg-icon"
                        src="/media/svg/icons/Neolex/Basic/power.svg"
                      />
                    </span>
                    <span class="navi-text text-primary">Active</span>
                  </a>
                </b-dropdown-text>
              </template>
            </action-dropdown>
          </td>
          <td>
            <div class="status" :class="getStatus(item.status)">
              {{ getStatus(item.status) }}
            </div>
          </td>
          <td class="text-center">
            {{ item.type == 1 ? 'Ngày' : 'Tuần' }}
          </td>
          <td>
            {{ getScheduleType(item.classify) }}
          </td>
          <td>
            {{ getTime(item) }}
          </td>
          <td class="cell-overflow">
            <div class="text-overflow">
              {{ getPackages(item.selectedPackages) }}
            </div>
          </td>
        </template>
      </template-table>
    </Wrapper>
    <ModalMovementRoute
      v-if="showModal"
      :type="modalType"
      :id="routeId"
      @reset="resetModal"
      @change-status="loadData"
    />
  </div>
</template>
<script>
import { MODAL_TYPE } from '@/core/plugins/constants';
import { CHANGE_STATUS } from '@/core/services/store/movementRoute.module';
import { createNamespacedHelpers } from 'vuex';
import { SCHEDULE_TYPES } from '../../../core/plugins/constants';
const { mapActions } = createNamespacedHelpers('movementRoute');
export default {
  name: 'MovementRouteList',
  components: {
    ModalMovementRoute: () => import('./components/Modal.vue'),
  },
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      column: [
        {
          key: 'code',
          label: 'Mã',
          sortable: false,
          style: {
            width: '10%',
          },
        },
        {
          key: 'name',
          label: 'Tên lộ trình',
          sortable: false,
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
          style: {
            width: '20px',
          },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          style: {
            width: '5%',
          },
        },
        {
          key: 'classify',
          label: 'Nhóm',
          sortable: false,
          class: 'text-center',
          style: {
            width: '90px',
          },
        },
        {
          key: 'type',
          label: 'Loại',
          sortable: false,
          style: {
            width: '165px',
          },
        },
        {
          key: 'time',
          label: 'Thời gian',
          sortable: false,
          style: {
            width: '100px',
          },
        },
        {
          key: 'package',
          label: 'Gói dịch vụ',
          sortable: false,
        },
      ],
      data: [],
      sort: {
        by: null,
        order: null,
      },
      filters: {
        type: null,
        name: null,
      },
      showModal: false,
      modalType: null,
      routeId: null,
      types: [
        {
          text: 'Lộ trình ngày',
          value: 1,
        },
        {
          text: 'Lộ trình tuần',
          value: 2,
        },
      ],
    };
  },
  computed: {
    searchParams() {
      return {
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
        type: this.filters.type?.value || null,
        name: this.filters.name || null,
      };
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({ CHANGE_STATUS }),
    async loadData(loading = true) {
      loading && this.$store.commit('context/setLoading', true);
      try {
        const { meta, data } = await this.$api.get('/Agenda', {
          params: this.searchParams,
        });
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: meta.message,
          });
        }
        this.data = data;
        this.paging.total = meta.total;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        loading && this.$store.commit('context/setLoading', false);
      }
    },
    goToDetailScreen(item) {
      if (item.classify === SCHEDULE_TYPES.MOVEMENT_EXERCISES) {
        return this.$router.push({
          name: 'movement_route_detail',
          params: {
            id: item.id,
          },
        });
      }
      this.$router.push({
        name: 'agenda_detail',
        params: {
          id: item.id,
        },
      });
    },
    resetPaging() {
      this.paging.page = 1;
      this.loadData();
    },
    searchDebounce(value, type) {
      if (type === 'code') {
        this.filters.code = value;
      } else {
        this.filters.name = value;
      }
      this.resetPaging();
    },
    create() {
      this.showModal = true;
      this.modalType = MODAL_TYPE.CREATE;
      this.$store.commit(`context/setModal`, true);
    },
    editRoute(item) {
      this.routeId = item.id;
      this.modalType = MODAL_TYPE.EDIT;
      this.showModal = true;
      this.$store.commit(`context/setModal`, true);
    },
    async copyRoute(item) {
      try {
        this.$store.commit('context/setLoading', true);
        const { meta, error } = await this.$api.post(
          `/Roadmap/${item.id}/Clone`,
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        await this.loadData(false);
        this.$toastr.s({
          title: 'Thành công !',
          msg: `Copy ${item.name} thành công`,
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    sortRequest(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
      this.loadData();
    },
    resetRequest() {
      this.filters = {
        name: null,
        code: null,
      };
      this.resetPaging();
      document.body.click();
    },
    searchRequest() {
      this.resetPaging();
      document.body.click();
    },
    showBtnActive(item) {
      if ([0, 2].includes(item.status)) return true;
      return false;
    },
    resetModal() {
      this.showModal = false;
      this.modalType = null;
      this.routeId = null;
    },
    getPackages(packages) {
      if (!packages.length) return null;
      return packages.map((el) => el.text).join();
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'inactive';
        case 1:
          return 'active';

        default:
          return 'draft';
      }
    },
    showPopupChangeStatus(item, status) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          status ? 'active' : 'inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.changeStatus(item, status);
          }
        }.bind(this),
      );
    },
    async changeStatus(item, status) {
      this.$store.commit('context/setLoading', true);
      const params = {
        id: item.id,
        state: status,
      };
      const { error } = await this.CHANGE_STATUS(params);
      if (!error) {
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
        this.loadData();
      } else {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
      this.$store.commit('context/setLoading', false);
    },
    getScheduleType(type) {
      switch (type) {
        case SCHEDULE_TYPES.MOVEMENT_EXERCISES:
          return 'Bài tập vận động';
        case SCHEDULE_TYPES.FREE:
          return 'Hoạt động tự do';
        case SCHEDULE_TYPES.KPI:
          return 'Hoạt động gắn với KPI';
        case SCHEDULE_TYPES.COMMON:
          return 'Hoạt động chung';

        default:
          return 'Bài học';
      }
    },
    getTime(item) {
      if (item.type === 1) {
        return `${item.day} ngày`;
      }

      return `${item.week} tuần`;
    },
  },
};
</script>
<style lang="scss" scoped>
.route-input {
  min-width: 220px;
}

.mw-400 {
  min-width: 400px;
}

.status {
  padding: 6px 24px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  width: 96px;
  text-align: center;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }

  &.draft {
    background: #888c9f;
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
